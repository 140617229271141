<template>
  <!-- Version: 1.2 -->
  <h2>1. Descrizione del gioco</h2>
  <p>
    Il gioco Campionato mondiale di hockey su ghiaccio IIHF di Highlight Games è una simulazione software esclusiva di
    momenti salienti di partite di hockey su ghiaccio selezionati in maniera casuale per creare opportunità di gioco.
  </p>
  <h2>2. Descrizione e lunghezza del gioco</h2>
  <p>
    Un singolo evento di un gioco di scommesse virtuali dura in media 5 minuti e comprende gli annunci relativi alla
    partita per quel gioco, il video della partita e i risultati.
  </p>
  <p>
    La selezione degli eventi di hockey su ghiaccio è statisticamente indipendente, casuale e imprevedibile ed è
    generata dal generatore di numeri casuali (RNG).
  </p>
  <h2>3. Mercati di scommesse</h2>
  <h3>3.1 Somma goal (inclusi tempi supplementari)</h3>
  <p>
    Il significato della scommessa Somma esatta di goal è che in una singola partita deve essere segnato un determinato
    numero di goal. Quindi, questa variante di scommessa significa che si deve pronosticare un numero ben preciso di
    goal indipendentemente dal risultato finale di un’intera partita.
  </p>
  <h3>3.2 Somma goal casa (inclusi tempi supplementari)</h3>
  <p>Una scommessa sul numero esatto di goal che la squadra di casa segnerà nella partita.</p>
  <h3>3.3 Somma goal ospite (inclusi tempi supplementari)</h3>
  <p>Una scommessa sul numero esatto di goal che la squadra ospite segnerà nella partita.</p>
  <h3>3.4 Entrambe le squadre segnano (BTTS) (tempi regolamentari)</h3>
  <p>
    Per vincere una scommessa BTTS (Both Teams To Score, Entrambe le squadre segnano), entrambe le squadre dovranno
    segnare almeno un goal, rendendo qualsiasi risultato da 1-1 in su una scommessa vincente. La scommessa
    &quot;Entrambe le squadre segnano&quot; dà agli scommettitori la possibilità di approfittare di tali eventi, al fine
    di ottenere un profitto quando solo una delle due squadre è riuscita a segnare.
  </p>
  <h3>3.5 Risultato esatto (inclusi tempi supplementari)</h3>
  <p>
    Scommettere sul Risultato esatto significa scommettere su quello che si pensa sarà il risultato finale di una
    partita di hockey su ghiaccio, con un massimo di 5 goal; in una partita i risultati possibili sono 18.
  </p>
  <h3>3.6 Esito finale (1X2) (tempi regolamentari)</h3>
  <p>
    Una scommessa sulla vittoria della squadra di casa (1), sulla vittoria della squadra ospite (2) o sulla partita che
    termina con un pareggio (X).
  </p>
  <h3>3.7 Esito finale (1,2) (inclusi tempi supplementari)</h3>
  <p>Una scommessa sulla vittoria della squadra di casa (1), sulla vittoria della squadra ospite (2).</p>
  <h3>3.8 Esito finale e Over/Under 3,5 goal (tempi regolamentari)</h3>
  <p>Una scommessa sull’esito della partita e sul numero totale di goal segnati inferiore o superiore a 3,5 goal.</p>
  <h3>3.9 Esito finale e Over/Under 3,5 goal (inclusi tempi supplementari)</h3>
  <p>Una scommessa sull’esito della partita e sul numero totale di goal segnati inferiore o superiore a 3,5 goal.</p>
  <h3>3.10 Over/Under 2,5 casa o ospite (inclusi tempi supplementari)</h3>
  <p>Una scommessa sul fatto che la squadra di casa o la squadra ospite segnerà più o meno di 2,5 goal.</p>
  <h3>3.11 Over 2,5, 3,5, 4,5 goal totali (inclusi tempi supplementari)</h3>
  <p>
    Una scommessa sul numero totale di goal segnati durante la partita da entrambe le squadre, superiore a 2,5, 3,5 o
    4,5.
  </p>
  <h3>3.12 Opzione scommessa a 2 vie (tempi regolamentari)</h3>
  <p>
    Un’opzione di scommessa con le quote stabilite per determinare la squadra favorita o sfavorita nella partita. Questo
    svantaggio tra le 2 squadre genera un’opzione di scommessa con un handicap di “mezzo goal”, in cui gli esiti
    possibili sono due.
  </p>
  <h3>3.13 Opzione scommessa a 3 vie (tempi regolamentari)</h3>
  <p>
    Un’opzione di scommessa con le quote stabilite per determinare la squadra favorita o sfavorita nella partita. Questo
    svantaggio tra le 2 squadre genera un’opzione di scommessa con un handicap di un “goal intero”, in cui gli esiti
    possibili sono 3 (incluso cioè il pareggio).
  </p>
  <h3>3.14 Periodo con più goal (tempi regolamentari)</h3>
  <p>
    Una scommessa su quale periodo avrà il maggior numero di gol segnati. Può essere il 1˚ periodo, il 2˚ Periodo, il 3˚
    Periodo o una parità.
  </p>
  <h3>3.15 Squadra che vince più periodi (tempi regolamentari)</h3>
  <p>
    Una scommessa su quale squadra vincerà il maggior numero di periodi. Può essere la squadra di casa, la squadra
    ospite o un pareggio.
  </p>
  <h3>3.16 Prima squadra a segnare (inclusi tempi supplementari)</h3>
  <p>Una scommessa su quale sarà la prima squadra a segnare nel gioco.</p>
</template>
